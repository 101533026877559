var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Filtros"}},[_c('div',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":_vm.swtichAllowInactivate},model:{value:(_vm.allowInactivates),callback:function ($$v) {_vm.allowInactivates=$$v},expression:"allowInactivates"}},[_vm._v(" Mostrar inativos? ")])],1),_c('div',{style:({
        overflowX: 'auto',
        width: '100%',
        display: 'flex',
        marginBottom: '20px',
        border: '1px solid',
        borderColor: '#d5d5d5',
        borderRadius: '4px',
        paddingLeft: '5px'
      })},_vm._l((_vm.users),function(user){return _c('div',{key:user.id,style:({
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
          alignItems: 'center'
        })},[_c('input',{attrs:{"type":"checkbox","checked":""},on:{"change":function($event){return _vm.getTasksBySelect(user.member_id)}}}),_c('span',{staticClass:"cutom-bullet",style:({
            borderRadius: '50%',
            display: 'block',
            color: user.color,
            background: user.color,
            width: '15px',
            height: '15px'
          }),on:{"click":function($event){return _vm.handleClickBullet(user)}}}),_c('div',{style:({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          })},[_c('p',{style:({
              marginRight: '10px',
              marginTop: '12px'
            })},[_vm._v(" "+_vm._s(user.username)+" ")])])])}),0),_c('b-row',{staticClass:"mb-2 align-items-center"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Data de Início"}},[_c('b-form-input',{attrs:{"type":"month"},on:{"change":_vm.updateDates},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Data de Fim"}},[_c('b-form-input',{attrs:{"type":"month"},on:{"change":_vm.updateDates},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center mt-1",attrs:{"cols":"4"}},[_c('b-button',{staticClass:"cta",attrs:{"variant":"primary","block":""},on:{"click":_vm.allPeriodSwitch}},[_vm._v(" Todo Período ")])],1)],1)],1),_c('b-card',{attrs:{"title":""}},[(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"50vh"}},[_c('div',{staticClass:"spinner-border spinner-border-sm"})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"mt-2"},[_c('b-table',{ref:"mytab",staticClass:"text-left",attrs:{"responsive":"sm","small":"","striped":"","hover":"","fields":_vm.fields,"items":_vm.items,"per-page":10,"current-page":0,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title ? item.title : item.task_title.task_title))]),_c('br'),_c('small',[_vm._v(" "+_vm._s(item.notes ? 'Obs.: ' + item.notes : 'Sem descrição'))])]}},{key:"cell(project)",fn:function(ref){
            var item = ref.item;
return [_c('strong',[_vm._v("#"+_vm._s(item.project.project_code))]),_c('p',[_vm._v(_vm._s(item.project.project_name))])]}},{key:"cell(actions)",fn:function(props){return [_c('div',{on:{"click":function($event){return _vm.handleUpdateModal(props.item)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],attrs:{"title":"Editar fornecedor","icon":"EditIcon","size":"15"}}),_vm._v("Editar ")],1)]}},{key:"cell(username)",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('div',{style:({
                display: 'flex',
                alignItems: 'center'
              })},[_c('span',{style:({
                  borderRadius: '50%',
                  //   display: 'block',
                  background: item.member.color,
                  width: '10px',
                  height: '10px'
                })}),_c('p',{style:({
                  height: 'auto',
                  paddingLeft: '5px',
                  paddingTop: '10px'
                })},[_vm._v(" "+_vm._s(item.member.access.user.username)+" ")])]),_c('small',[_vm._v(_vm._s(item.member.access.user.email))])])]}},{key:"cell(date)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.date, 'dd/MM/yyyy'))+" ")]}},{key:"cell(start)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.start.slice(0, 5))+" ")]}},{key:"cell(end)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.end.slice(0, 5))+" ")]}},{key:"cell(comments)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.notes)+" ")]}}])}),_c('b-pagination',{staticClass:"justify-content-center",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"aria-controls":"my-table"},on:{"change":_vm.getPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('b-col',{attrs:{"cols":"12","align":"end"}},[_c('b',[_vm._v("Total de tarefas: "+_vm._s(_vm.totalRows))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }